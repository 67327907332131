import React, { useReducer, useEffect, useRef, useCallback } from 'react';
import './Saviochat.css';
import Sidebar from './Sidebar';
import ChatHeader from './ChatHeader';
import ChatContent from './ChatContent';
import InputArea from './InputArea';
import { 
  initialState, 
  reducer, 
  addMessage, 
  setInputValue, 
  setIsTyping, 
  setIsLoading, 
  updateLastMessage,
  setActiveConversation,
  setSelectedModel,
  setSelectedRole,
  setActiveView,
  createNewConversation,
  deleteConversation
} from '../state/chatReducer';
import { sendMessage, sendMessageGroq } from '../utils/api';
import { AI_MODELS, AI_ROLES } from '../constants/aiConfig';

const Saviochat = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const endOfMessagesRef = useRef(null);

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [state.conversations]);

  const simulateTyping = useCallback(async (text, delay = 30) => {
    let partialText = '';
    for (let i = 0; i < text.length; i++) {
      await new Promise(resolve => setTimeout(resolve, delay));
      partialText += text[i];
      dispatch(updateLastMessage(partialText));
    }
  }, []);

  const handleSend = useCallback(async () => {
    if (state.inputValue.trim() && !state.isTyping && !state.isLoading) {
      const userMessage = { id: Date.now(), text: state.inputValue, sender: 'user' };
      dispatch(addMessage(userMessage));
      dispatch(setInputValue(''));
      dispatch(setIsLoading(true));

      try {
        console.log('Sending message with model:', state.selectedModel.id, 'and role:', state.selectedRole);
        let response;
        if (state.selectedModel.id.startsWith('groq-')) {
          // Use Groq API for Groq models
          response = await sendMessageGroq(state.inputValue, state.selectedModel.id.replace('groq-', ''), state.selectedRole);
        } else {
          // Use OpenAI API for other models
          response = await sendMessage(state.inputValue, state.selectedModel.id, state.selectedRole);
        }
        const aiMessage = { 
          id: Date.now(), 
          text: '', 
          sender: 'ai',
          role: state.selectedRole,
          model: state.selectedModel.id
        };
        dispatch(addMessage(aiMessage));
        dispatch(setIsTyping(true));
        await simulateTyping(response);
        dispatch(setIsTyping(false));
      } catch (error) {
        console.error('Error sending message:', error);
        const errorMessage = { id: Date.now(), text: `Error: ${error.message}`, sender: 'ai' };
        dispatch(addMessage(errorMessage));
      }

      dispatch(setIsLoading(false));
    }
  }, [state.inputValue, state.isTyping, state.isLoading, state.selectedModel.id, state.selectedRole, simulateTyping]);

  const handleInputChange = useCallback((value) => {
    dispatch(setInputValue(value));
  }, []);

  const handleModelChange = useCallback((model) => {
    dispatch(setSelectedModel(model));
  }, []);

  const handleRoleChange = useCallback((role) => {
    dispatch(setSelectedRole(role));
  }, []);

  const handleViewChange = useCallback((view) => {
    dispatch(setActiveView(view));
  }, []);

  const handleNewConversation = useCallback(() => {
    dispatch(createNewConversation());
  }, []);

  const handleDeleteConversation = useCallback((id) => {
    dispatch(deleteConversation(id));
  }, []);

  const handleSelectConversation = useCallback((id) => {
    dispatch(setActiveConversation(id));
  }, []);

  return (
    <div className="saviochat-container">
      <Sidebar 
        activeView={state.activeView}
        conversations={state.conversations}
        activeConversationId={state.activeConversationId}
        onViewChange={handleViewChange}
        onNewConversation={handleNewConversation}
        onDeleteConversation={handleDeleteConversation}
        onSelectConversation={handleSelectConversation}
      />
      <div className="chat-area">
        <ChatHeader 
          selectedModel={state.selectedModel}
          selectedRole={state.selectedRole}
          onModelChange={handleModelChange}
          onRoleChange={handleRoleChange}
          AI_MODELS={AI_MODELS}
          AI_ROLES={AI_ROLES}
        />
        <ChatContent 
          messages={state.conversations[state.activeConversationId] || []}
          isTyping={state.isTyping}
          endOfMessagesRef={endOfMessagesRef} 
        />
        <InputArea 
          inputValue={state.inputValue}
          isLoading={state.isLoading}
          onInputChange={handleInputChange}
          onSend={handleSend} 
        />
      </div>
    </div>
  );
};

export default Saviochat;