import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  conversations: {},
  activeConversationId: null,
  inputValue: '',
  isTyping: false,
  isLoading: false,
  selectedModel: { id: 'gpt-3.5-turbo', name: 'GPT-3.5' },  // Update this line
  selectedRole: 'assistant',
  activeView: 'singleChat',
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      const { conversationId, message } = action.payload;
      const conversation = state.conversations.find(c => c.id === conversationId);
      if (conversation) {
        conversation.messages.push(message);
      }
    },
    setActiveConversation: (state, action) => {
      state.activeConversation = action.payload;
    },
    addConversation: (state) => {
      const newId = Math.max(...state.conversations.map(c => c.id)) + 1;
      state.conversations.push({ id: newId, title: `Conversation ${newId}`, messages: [] });
      state.activeConversation = newId;
    },
    setSelectedModel: (state, action) => {
      state.selectedModel = action.payload;
    },
  },
});

export const { addMessage, setActiveConversation, addConversation, setSelectedModel } = chatSlice.actions;

export default chatSlice.reducer;
