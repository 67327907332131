import axios from 'axios';

const OPENAI_API_URL = 'https://api.openai.com/v1/chat/completions';
const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

const GROQ_API_URL = 'https://api.groq.com/openai/v1/chat/completions';
const GROQ_API_KEY = process.env.REACT_APP_GROQ_API_KEY;

export const sendMessage = async (message, model, role) => {
  try {
    console.log('Sending message:', message, 'Model:', model, 'Role:', role);
    const response = await axios.post(
      OPENAI_API_URL,
      {
        model: model,
        messages: [
          { role: 'system', content: `You are a ${role}.` },
          { role: 'user', content: message },
        ],
      },
      {
        headers: {
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('API Response:', response.data);
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error in API call:', error.response ? error.response.data : error.message);
    throw new Error(`API Error: ${error.response ? error.response.data.error.message : error.message}`);
  }
};

export const sendMessageGroq = async (message, model, role) => {
  try {
    console.log('Sending message to Groq:', message, 'Model:', model, 'Role:', role);
    const response = await axios.post(
      GROQ_API_URL,
      {
        model: model, // Groq models like 'mixtral-8x7b-32768' or 'llama2-70b-4096'
        messages: [
          { role: 'system', content: `You are a ${role}.` },
          { role: 'user', content: message },
        ],
        temperature: 0.7,
        max_tokens: 1000,
      },
      {
        headers: {
          'Authorization': `Bearer ${GROQ_API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('Groq API Response:', response.data);
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error in Groq API call:', error.response ? error.response.data : error.message);
    throw new Error(`Groq API Error: ${error.response ? error.response.data.error.message : error.message}`);
  }
};