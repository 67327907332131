import React from 'react';
import './InputArea.css';

const InputArea = ({ inputValue, isLoading, onInputChange, onSend }) => {
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSend();
    }
  };

  return (
    <div className="input-area">
      <textarea
        value={inputValue}
        onChange={(e) => onInputChange(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="Type your message here..."
        disabled={isLoading}
      />
      <button onClick={onSend} disabled={isLoading}>
        Send
      </button>
    </div>
  );
};

export default InputArea;