import React, { useState } from 'react';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { auth } from './firebase'; // Import auth instead of db
import './WaitingList.css';

const WaitingList = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const db = getFirestore(auth.app); // Initialize Firestore using the auth object
      await addDoc(collection(db, 'waitlist'), {
        email: email,
        timestamp: new Date()
      });
      setMessage('Thank you! You will receive an email when Savioz is ready.');
      setEmail('');
    } catch (error) {
      setMessage('An error occurred. Please try again.');
      console.error('Error adding document: ', error);
    }
  };

  return (
    <div className="waiting-list-container">
      <div className="waiting-list-card">
        <h1 className="waiting-list-title">Join the Savioz Waiting List</h1>
        <p className="waiting-list-description">
          Be the first to know when Savioz, your personalized chatbot for multiple usage, is ready!
        </p>
        <form onSubmit={handleSubmit} className="waiting-list-form">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
            className="waiting-list-input"
          />
          <button
            type="submit"
            className="waiting-list-button"
          >
            Join Waiting List
          </button>
        </form>
        {message && <p className="waiting-list-message">{message}</p>}
      </div>
    </div>
  );
};

export default WaitingList;