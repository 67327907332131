import React from 'react';
import { MessageSquare, Link, Globe, Settings, User, Plus } from 'lucide-react';

const NavItem = ({ icon: Icon, text, view, activeView, onClick }) => (
  <a
    href="#"
    className={`nav-item ${view && activeView === view ? 'active' : ''}`}
    onClick={() => onClick && onClick(view)}
  >
    <Icon size={18} />
    <span>{text}</span>
  </a>
);

const Sidebar = ({ activeView, dispatch, conversations = [] }) => {
  const handleNavClick = (view) => {
    dispatch({ type: 'SET_ACTIVE_VIEW', payload: view });
  };

  //const handleAddConversation = () => {
    //dispatch({ type: 'ADD_CONVERSATION' });
  //};

  const handleAddConversation = () => {
    // You might want to perform some actions before reloading
    // For example, saving some data to localStorage
    localStorage.setItem('lastAction', 'addedConversation');
    
    // This will reload the current page
    window.location.reload();
    
    // Note: Any code after window.location.reload() will not be executed
    // as the page will immediately start reloading
  };

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <h1>Saviochat</h1>
        <p>Assistant IA professionnel</p>
      </div>
      <nav className="sidebar-nav">
        <NavItem 
          icon={MessageSquare} 
          text="Conversation simple ⚡" 
          view="singleChat" 
          activeView={activeView} 
          onClick={handleNavClick} 
        />
        <NavItem 
          icon={MessageSquare} 
          text="Conversation double 🔒" 
          view="doubleChat" 
          activeView={activeView} 
          onClick={handleNavClick} 
        />
        <NavItem 
          icon={Link} 
          text="Interconnexion 🔒" 
          view="interconnect" 
          activeView={activeView} 
          onClick={handleNavClick} 
        />
        <NavItem icon={Globe} text="Connexion Internet 🔒" />
        <NavItem icon={Settings} text="Paramètres" />
        <NavItem icon={User} text="Profil" />
      </nav>
      {conversations.length > 0 && (
        <div className="conversations-list">
          {conversations.map((conversation) => (
            <div 
              key={conversation.id} 
              className="conversation-item"
              onClick={() => dispatch({ type: 'SET_ACTIVE_CONVERSATION', payload: conversation.id })}
            >
              {conversation.title}
            </div>
          ))}
        </div>
      )}
      <button className="add-conversation-button" onClick={handleAddConversation}>
        <Plus size={18} /> Nouvelle conversation
      </button>
    </div>
  );
};

export default Sidebar;