

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ChatHeader.css';

const ChatHeader = ({ selectedModel, selectedRole, onModelChange, onRoleChange, AI_MODELS, AI_ROLES }) => {
  const navigate = useNavigate();

  const handleAddSavioz = () => {
    navigate('/waitlist');
  };

  return (
    <div className="chat-header">
      <div className="select-container">
        <select
          className="model-select"
          value={selectedModel.id}
          onChange={(e) => {
            const newModel = AI_MODELS.find(model => model.id === e.target.value);
            onModelChange(newModel);
          }}
        >
          {AI_MODELS.map(model => (
            <option key={model.id} value={model.id}>{model.name}</option>
          ))}
        </select>
        <select
          className="role-select"
          value={selectedRole}
          onChange={(e) => onRoleChange(e.target.value)}
        >
          {AI_ROLES.map(role => (
            <option key={role} value={role}>{role}</option>
          ))}
        </select>
      </div>
      <button className="add-savioz-btn" onClick={handleAddSavioz}>Add Savioz</button>
    </div>
  );
};

export default ChatHeader;