// chatReducer.js
import { AI_MODELS, AI_ROLES } from '../constants/aiConfig';

// Action types
const SET_ACTIVE_CONVERSATION = 'SET_ACTIVE_CONVERSATION';
const ADD_MESSAGE = 'ADD_MESSAGE';
const UPDATE_LAST_MESSAGE = 'UPDATE_LAST_MESSAGE';
const SET_INPUT_VALUE = 'SET_INPUT_VALUE';
const SET_IS_TYPING = 'SET_IS_TYPING';
const SET_IS_LOADING = 'SET_IS_LOADING';
const SET_SELECTED_MODEL = 'SET_SELECTED_MODEL';
const SET_SELECTED_ROLE = 'SET_SELECTED_ROLE';
const SET_ACTIVE_VIEW = 'SET_ACTIVE_VIEW';
const CREATE_NEW_CONVERSATION = 'CREATE_NEW_CONVERSATION';
const DELETE_CONVERSATION = 'DELETE_CONVERSATION';

export const initialState = {
  conversations: {},
  activeConversationId: null,
  inputValue: '',
  isTyping: false,
  isLoading: false,
  selectedModel: AI_MODELS[0],
  selectedRole: AI_ROLES[0],
  activeView: 'chat',
};

export const reducer = (state, action) => {
  console.log('Action:', action.type, 'Payload:', action.payload);

  switch (action.type) {
    case SET_ACTIVE_CONVERSATION:
      return {
        ...state,
        activeConversationId: action.payload,
      };

    case ADD_MESSAGE:
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [state.activeConversationId]: [
            ...(state.conversations[state.activeConversationId] || []),
            action.payload,
          ],
        },
      };

    case UPDATE_LAST_MESSAGE: {
      const conversationMessages = state.conversations[state.activeConversationId] || [];
      const updatedMessages = [...conversationMessages];
      if (updatedMessages.length > 0) {
        updatedMessages[updatedMessages.length - 1] = {
          ...updatedMessages[updatedMessages.length - 1],
          text: action.payload,
        };
      }
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [state.activeConversationId]: updatedMessages,
        },
      };
    }

    case SET_INPUT_VALUE:
      return {
        ...state,
        inputValue: action.payload,
      };

    case SET_IS_TYPING:
      return {
        ...state,
        isTyping: action.payload,
      };

    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case SET_SELECTED_MODEL:
      return {
        ...state,
        selectedModel: action.payload,
      };

    case SET_SELECTED_ROLE:
      return {
        ...state,
        selectedRole: action.payload,
      };

    case SET_ACTIVE_VIEW:
      return {
        ...state,
        activeView: action.payload,
      };

    case CREATE_NEW_CONVERSATION: {
      const newConversationId = Date.now().toString();
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [newConversationId]: [],
        },
        activeConversationId: newConversationId,
      };
    }

    case DELETE_CONVERSATION: {
      const { [action.payload]: deletedConversation, ...remainingConversations } = state.conversations;
      const newActiveId = state.activeConversationId === action.payload
        ? Object.keys(remainingConversations)[0] || null
        : state.activeConversationId;
      return {
        ...state,
        conversations: remainingConversations,
        activeConversationId: newActiveId,
      };
    }

    default:
      console.warn('Unknown action type:', action.type);
      return state;
  }
};

// Action creators
export const setActiveConversation = (id) => ({ type: SET_ACTIVE_CONVERSATION, payload: id });
export const addMessage = (message) => ({ type: ADD_MESSAGE, payload: message });
export const updateLastMessage = (text) => ({ type: UPDATE_LAST_MESSAGE, payload: text });
export const setInputValue = (value) => ({ type: SET_INPUT_VALUE, payload: value });
export const setIsTyping = (isTyping) => ({ type: SET_IS_TYPING, payload: isTyping });
export const setIsLoading = (isLoading) => ({ type: SET_IS_LOADING, payload: isLoading });
export const setSelectedModel = (model) => ({ type: SET_SELECTED_MODEL, payload: model });
export const setSelectedRole = (role) => ({ type: SET_SELECTED_ROLE, payload: role });
export const setActiveView = (view) => ({ type: SET_ACTIVE_VIEW, payload: view });
export const createNewConversation = () => ({ type: CREATE_NEW_CONVERSATION });
export const deleteConversation = (id) => ({ type: DELETE_CONVERSATION, payload: id });