import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { store } from './redux/store';
import SavioChat from './components/SavioChat';
import WaitingList from './components/WaitingList'; // Import the WaitingList component
import { Analytics } from '@vercel/analytics/react'; // Import Vercel Analytics
import './App.css';

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Routes>
            <Route
              path="/"
              element={
                <main>
                  <SavioChat/>
                </main>
              }
            />
            <Route
              path="/waitlist"
              element={
                <main>
                  <WaitingList />
                </main>
              }
            />
          </Routes>
          <Analytics /> {/* Add Vercel Analytics component */}
        </div>
      </Router>
    </Provider>
  );
};

export default App;
