import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration


const firebaseConfig = {
  apiKey: 'AIzaSyC7psdEphyPkDOfbpF6cF0SoSsjPrDdtFY',
  authDomain: 'svio-e8abd.firebaseapp.com',
  projectId: 'svio-e8abd',
  storageBucket: 'svio-e8abd.appspot.com',
  messagingSenderId: '930851304849',
  appId: '1:930851304849:web:8cb78bef42e46676d91630',
};
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  
  // Initialize Firebase Authentication and get a reference to the service
  const auth = getAuth(app);
  
  export { auth };