/*

const AI_MODELS = [
    { id: 'gpt-3.5-turbo', name: 'GPT-3.5', color: '#10B981' },
    { id: 'gpt-4', name: 'GPT-4', color: '#3B82F6' },
    { id: 'text-davinci-002', name: 'Davinci', color: '#8B5CF6' },
  ];
  
  const AI_ROLES = [
    { id: 'default', name: 'Assistant Général' },
    { id: 'academic', name: 'Chercheur Académique' },
    { id: 'programmer', name: 'Développeur Logiciel' },
    { id: 'writer', name: 'Écrivain Créatif' },
    { id: 'analyst', name: 'Analyste de Données' },
    { id: 'tutor', name: 'Tuteur Éducatif' },
    { id: 'coach', name: 'Coach de Vie' },
    { id: 'marketer', name: 'Stratège Marketing' },
    { id: 'financial', name: 'Conseiller Financier' },
    { id: 'legal', name: 'Assistant Juridique' },
    { id: 'medical', name: 'Information Médicale' },
    { id: 'historian', name: 'Historien' },
    { id: 'philosopher', name: 'Philosophe' },
    { id: 'scientist', name: 'Scientifique' },
    { id: 'artist', name: 'Critique d\'Art' },
    { id: 'chef', name: 'Expert Culinaire' },
    { id: 'travel', name: 'Guide de Voyage' },
    { id: 'fitness', name: 'Coach Sportif' },
    { id: 'translator', name: 'Traducteur Linguistique' },
    { id: 'journalist', name: 'Journaliste d\'Investigation' },
    { id: 'engineer', name: 'Ingénieur' },
    { id: 'psychologist', name: 'Psychologue' },
    { id: 'career', name: 'Conseiller en Carrière' },
    { id: 'business', name: 'Consultant en Affaires' },
    { id: 'sociologist', name: 'Sociologue' },
    { id: 'environmentalist', name: 'Expert en Environnement' },
    { id: 'ethicist', name: 'Consultant en Éthique' },
    { id: 'futurist', name: 'Futurologue' },
    { id: 'storyteller', name: 'Conteur' },
    { id: 'debate', name: 'Modérateur de Débat' },
  ];


*/

  export const AI_MODELS = [
    { id: 'gpt-3.5-turbo', name: 'GPT-3.5' },
    { id: 'gpt-4', name: 'GPT-4' }, 
    { id: 'groq-mixtral-8x7b-32768', name: 'cool Groq Mixtral 8x7B' },
      // Only include this if you have access to GPT-4
  ];
  
  export const AI_ROLES = [
    'assistant',
    'teacher',
    'translator',
    'programmer',
    'creative writer',
  ];
  
  