import React, { useEffect, useRef, useState } from 'react';
import { MessageSquare, Loader, Download, Code } from 'lucide-react';
import Editor from '@monaco-editor/react';
import './ChatContent.css';

const ChatContent = ({ messages, isTyping, endOfMessagesRef }) => {
  const scrollRef = useRef(null);
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const [showEditor, setShowEditor] = useState(false);
  const [editorContent, setEditorContent] = useState('');
  const [currentEditingMessageId, setCurrentEditingMessageId] = useState(null);
  const [editorLanguage, setEditorLanguage] = useState('javascript');

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [displayedMessages, isTyping]);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage && lastMessage.sender === 'ai') {
      let typedText = '';
      const intervalId = setInterval(() => {
        if (typedText.length < lastMessage.text.length) {
          typedText = lastMessage.text.slice(0, typedText.length + 1);
          setDisplayedMessages([...messages.slice(0, -1), { ...lastMessage, text: typedText }]);
        } else {
          clearInterval(intervalId);
        }
      }, 20);

      return () => clearInterval(intervalId);
    } else {
      setDisplayedMessages(messages);
    }
  }, [messages]);

  const detectLanguage = (code) => {
    // This is a simple detection. You might want to use a more sophisticated method.
    if (code.includes('def ') || code.includes('import ')) return 'python';
    if (code.includes('function ') || code.includes('const ')) return 'javascript';
    if (code.includes('public class ') || code.includes('System.out.println')) return 'java';
    return 'plaintext';
  };

  const renderMessage = (message) => (
    <div key={message.id} className={`message ${message.sender}`}>
      <div className="message-header">
        {message.sender === 'ai' ? (
          <MessageSquare className="message-icon" size={18} />
        ) : (
          <div className="user-avatar">{message.sender[0].toUpperCase()}</div>
        )}
        {message.sender === 'ai' && message.role && (
          <div className="message-role">{message.role}</div>
        )}
      </div>
      <div className="message-text">
           {message.text}
        {message.sender === 'ai' && message === displayedMessages[displayedMessages.length - 1] && (
          <span className="cursor"></span>
        )}
      </div>
      {message.sender === 'ai' && (
        <button className="code-button" onClick={() => handleCodeEditor(message)}>
          <Code size={18} />
        </button>
      )}
    </div>
  );






  

  const handleCodeEditor = (message) => {
    const detectedLanguage = detectLanguage(message.text);
    setEditorLanguage(detectedLanguage);
    setEditorContent(message.text);
    setCurrentEditingMessageId(message.id);
    setShowEditor(true);
  };

  const handleEditorChange = (value) => {
    setEditorContent(value);
  };

  const closeEditor = () => {
    setShowEditor(false);
    setCurrentEditingMessageId(null);
  };

  const saveEditorContent = () => {
    const updatedMessages = displayedMessages.map(msg => 
      msg.id === currentEditingMessageId ? { ...msg, text: editorContent } : msg
    );
    setDisplayedMessages(updatedMessages);
    closeEditor();
  };

  const downloadConversation = () => {
    const conversationText = displayedMessages
      .map(message => `${message.sender.toUpperCase()}: ${message.text}`)
      .join('\n\n');
    
    const blob = new Blob([conversationText], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'conversation.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="chat-content">
      <button className="download-button" onClick={downloadConversation} title="Download Conversation">
        <Download size={18} />
      </button>
      
      {displayedMessages.map(renderMessage)}
      {isTyping && (
        <div className="message ai thinking">
          <div className="message-header">
            <MessageSquare className="message-icon" size={18} />
          </div>
          <div className="thinking-indicator">
            <Loader className="spin" size={18} />
            AI is thinking...
          </div>
        </div>
      )}
      {showEditor && (
        <div className="editor-container">
          <Editor
            height="300px"
            language={editorLanguage}
            value={editorContent}
            onChange={handleEditorChange}
            theme="vs-dark"
          />
          <div className="editor-buttons">
            <button className="save-editor" onClick={saveEditorContent}>Save</button>
            <button className="close-editor" onClick={closeEditor}>Close</button>
          </div>
        </div>
      )}
      <div ref={scrollRef} />
    </div>
  );
};

export default ChatContent;